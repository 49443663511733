import CONFIG from  "../../config.js";
import {M,app,MIO} from  "../../lib/petite_ming_mock.js";


app.get("/crmHelpApiGetByApiGroupId", async (req, res) => {
    let {id}=req.params;
    let apiPath=  CONFIG.baseUrlCrmHelp("/open/crmhelp/api/getByApiGroupId");
    let r = await window.M.request.get(apiPath, {
        id: id
    });
    res.send(r);
});


app.get("/crmHelpApiGroupList", async (req, res) => {
    let apiPath=  CONFIG.baseUrlCrmHelp("/open/crmhelp/apiGroup/apiGroupTree");
    let r = await M.request.get(apiPath);
    res.send(r);
});


app.get("/crmHelpApiGroupBigTree", async (req, res) => {
    let apiPath=  CONFIG.baseUrlCrmHelp("/open/crmhelp/apiTree/getApiTree");
    let r = await M.request.get(apiPath);
    res.send(r);
});

app.get("/crmHelpApiList", async (req, res) => {
    let {page=1,num=10,groupId,keyword}=req.params;
    let apiPath=  CONFIG.baseUrlCrmHelp("/open/crmhelp/api/list");
    let r = await M.request.get(apiPath,{
        page,num,groupId,keyword,order:"func_name desc"
    });
    r.data.dataList.forEach(u=>u.key=u.id);
    res.send(r);
});


export default MIO;