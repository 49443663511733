import React from 'react';
import { connect } from 'react-redux';
import Tree from './Tree';
 class ApiGroupTreeManage extends React.Component{
    constructor(props){
           super(props);
           this.setGloableState=(state)=>{
               return this.props.dispatch({
                   type: "SET_API_STATE",
                   state: state,
               });
           };

           this.state = {
                 apiGroupTree:[],
                 treeData:[

                 ]
          }

    }
    async onSelect(item){
        if(item.category=="api"){
            let r= await window.MIO.crmHelpApiGetByApiGroupId({id:item.id});
            this.setGloableState({
                currentApi:r.data,
                currentTreeNode:item
            })
        }else {
            this.setGloableState({
                currentTreeNode:item
            })
        }
    }

    apiGroupBigTree2TreeData(treeData,apiGroupBigTree){
        if(apiGroupBigTree==null){
            return;
        }
        for (let i=0;i<apiGroupBigTree.length;i++ ){
            let apiGroupTreeItem=apiGroupBigTree[i];
            let title=apiGroupTreeItem.name;
            let value=apiGroupTreeItem.id;
            let o={
                title,
                id:apiGroupTreeItem.id,
                value,
                category:apiGroupTreeItem.category,
                children:[]
            };
            o.isOpenChild=true;
            treeData.push(o);
            if(apiGroupTreeItem.childen && apiGroupTreeItem.childen.length>0){
                this.apiGroupBigTree2TreeData(o.children,apiGroupTreeItem.childen)
            }
        }
    }


    async componentDidMount(){
        let treeData=[]
        let d= await window.MIO.crmHelpApiGroupBigTree();
        let apiGroupTree=d.data;
        this.apiGroupBigTree2TreeData(treeData,apiGroupTree);
        this.setState({
            treeData:treeData
        })
    }



    render(){      
        const {treeData} = this.state;
        return (<Tree
                treeData={treeData}       
                onSelect={(item)=>this.onSelect(item)}
                defaultExpandedKeys={['927']}
            />
        )
    }
}


export default connect(u=>u.ApiReducer)(ApiGroupTreeManage);
