import React, {Component, Fragment, useEffect, useState} from 'react';
import apiSolution from '../../api/apiSolution';
import ParagraphStyles from '../Common/ParagraphStyles';
import Common from '../Common/Common';
import FadeTransitions from '../Common/FadeTransitions';
import CONFIG from '../../config';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Divider from '@material-ui/core/Divider'
import './index.css'


const OpenTestDetail = () => {
    const isPc = useMediaQuery(CONFIG.minDeviceWidth)
    const [data, setData] = useState([])
    const [headerData,setHeaderData]=useState({
        headerLogoOssUrl:"https://langjie.oss-cn-hangzhou.aliyuncs.com/space/root/project/website/websiteimg/toolBox.png",
        softName:"Actio",
        version:"2023-08-29",
        publishDate:"2023-08-29",
        downLoadOssUrl:"https://langjie.oss-cn-hangzhou.aliyuncs.com/space/root/tool/zx/c2s.zip",
        softIconOssUrl:"https://langjie.oss-cn-hangzhou.aliyuncs.com/space/root/project/website/websiteimg/OpenTest.png"
    });
    useEffect(() => {
        const fetch = async() => {
            const result = await apiSolution.fetchOpenKnowLedge(223)
            let  headerLogo=JSON.parse(result.data[0].content.HeadLogo);
            let  headerDataJson=JSON.parse(result.data[0].content.HeadData);
            if(result.code === 200){
                setData(result.data);
                setHeaderData({
                    headerLogoOssUrl:headerLogo.ossurl,
                    softName:headerDataJson.softName,
                    version: headerDataJson.version,
                    publishDate:headerDataJson.publishDate,
                    downLoadOssUrl:headerDataJson.downLoadOssUrl,
                    softIconOssUrl:headerDataJson.softIconOssUrl
                });
            }
        }
        fetch()
    },[])

    const render = () => {
        if(data.length === 0) return
        const  bodyItemArr = []
        const content = data[0].content
        for(let key in content) {
            try {
                if(typeof content[key] === 'string') {
                    const it = JSON.parse(content[key])
                    if(it.type!=null){
                        continue
                    }
                    bodyItemArr.push(<div key={key} style={{width: '100%', display: 'flex', justifyContent: 'center', margin: "20px 0", alignSelf: "flex-start"}}>
                        <div style={{width: "70%", display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                            <h3>{key}</h3>
                            <div style={{fontSize: 16}}><span>{it.digest}</span></div>
                        </div>
                    </div>)
                }
            } catch (error) {
                console.log(error)
            }
        }
        return(
            <div>
                <div key="kit">
                    <div style={{display: 'flex', justifyContent: 'space-around', height: 240, alignItems: 'center', flexDirection: isPc ? "row" : "column"}}>
                        <div style={{backgroundImage: `url(${headerData.headerLogoOssUrl})`, width:"100%", height: "100%", maxWidth: 400,  backgroundSize:'contain', backgroundRepeat: "no-repeat", backgroundPosition:"center"}}></div>
                        <div  className={"DetailheadDataWrap"}>
                            <img src={headerData.softIconOssUrl}/>
                            <div >
                                <div>
                                    软件名: {headerData.softName}
                                </div>
                                <div>
                                    版本号: {headerData.version}`
                                </div>
                                <div>
                                    发布时间: {headerData.publishDate}
                                </div>
                                <div className="download"><Button
                                    onClick={()=>{
                                        window.MIO.df(headerData.downLoadOssUrl);
                                    }}
                                    startIcon={<CloudDownload/>} color="primary" variant="contained">演示下载</Button></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div key="imgItem">
                    <div style={{color: "#6495ed", textAlign: 'center', paddingBottom:"18px", fontSize: 16}}><span></span></div>
                    <div><Divider light={true} variant="fullWidth"/></div>
                    <div style={{display: 'flex', flexWrap: "wrap"}}>{bodyItemArr}</div>
                </div>
            </div>



            )

    }

    return(
        <FadeTransitions>
            <div>
                <div>{ParagraphStyles.RenderTitle(data)}</div>
                <div>{render()}</div>
            </div>
        </FadeTransitions>
    )
}

export default OpenTestDetail;