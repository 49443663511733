import React, { useEffect, useState } from 'react';
import fetchMemberVipKnowledge from '../../api/apiAboutMembervipclub';
import  ParagraphStyles from '../Common/ParagraphStyles';
import CONFIG from '../../config';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Common from '../Common/Common';
import FadeTransitions from '../Common/FadeTransitions';

const MemberVipConstituent = () => {
    const isPc = useMediaQuery(CONFIG.minDeviceWidth)
    const [data, setData] = useState([])
    useEffect(() =>{
        const fetch = async () => {
            const result = await fetchMemberVipKnowledge.fetchMemberVipKnowledge({
                contentId:198
            })
            if(result.code == 200) setData(result.data)
        }
        fetch()
    },[])

    const renderParagraph = () => {
        if(data.length === 0) return
        const obj = data[0].content
        let resArr = [];
        const transArr = Common.transToViewAll(obj);
        transArr.forEach((items, index) => {
            if (items.type === 'text') {
                items.valueArr.forEach((it, ind) => {
                    resArr.push(<p key={index + '-' + ind}>{it}</p>);
                });
            }else {
                items.valueArr.forEach((ite, ind) => {
                    resArr.push(
                        <div key={index + '2' + ind} style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: 'center'
                        }}>
                            {/*<img src={CONFIG.url(`/img/gallery/${ite}`)} alt="" width={300} height="160vw"/>*/}
                            <div style={{
                                backgroundImage: `url(${CONFIG.url(`/img/gallery/${ite}`)})`,
                                width: isPc ? (items.valueArr.length > 1 ? 300 : 450) : 300,
                                height: isPc ? (items.valueArr.length > 1 ? 220 : 300) : 220,
                                backgroundSize: 'contain',
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                cursor: "pointer"
                            }} onClick={() => {
                                window.open(CONFIG.url(`/img/gallery/${ite}`))
                            }}></div>
                            {/*{isPc ? <div></div>: <div><p>(点击图片查看原图)</p></div>}*/}
                            <div style={{textIndent: 0}}><p>{ite.slice(0, ite.indexOf('.'))}</p></div>
                        </div>
                    );
                })
            }
        });
        return resArr
    }
    return <FadeTransitions>
        <div style={{margin: isPc ? 40 : 20}}>
            <div></div>
            <div>
                {ParagraphStyles.ContentStyles(renderParagraph())}
            </div>
        </div>
    </FadeTransitions>
}


export default MemberVipConstituent;