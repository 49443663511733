import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function SliderForDetail(props){

    //let  htmlData=eval("`"+item.value+"`");
    const settings = {
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: props.speed,
        autoplay: props.autoplay,
        arrows: true
    }
    return (  <div style={{paddingBottom: props.paddingBottom,width:props.containerWith}}>
        <Slider {...settings}>
            {props.imgList.map((item, index) => (
                <div key={index}><div style={{backgroundImage: `url(${item})`, backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat", height:  props.height, width: props.width, margin: "auto", cursor: "pointer"}} onClick={() =>{}}></div></div>
            ))}
        </Slider>
    </div>);

}

export default SliderForDetail;