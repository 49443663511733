import React from 'react';
import './index.css'
const  arrowDownGray = 'https://langjie.oss-cn-hangzhou.aliyuncs.com/space/root/project/ruyunsuixing/img/xiala02.png';
const  arrowUpGray = 'https://langjie.oss-cn-hangzhou.aliyuncs.com/space/root/project/ruyunsuixing/img/shangla02.png';

class Tree extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            keys:[]
        }
    }
    componentDidMount(){
        const {defaultExpandedKeys,treeData,children} = this.props;
        //处理默认样式
        if(treeData && treeData.length>0){
            if(Array.isArray(defaultExpandedKeys) && defaultExpandedKeys.length>0) {
                treeData.map((item) => {
                    if (defaultExpandedKeys.includes(String(item.key))) {
                        item.isOpenChild = true;//展开子节点
                    }
                });
            }
        }
        //处理自定义样式
        if(children && children.length>0){
            let keys = [];
            children.map((item)=>{
                keys.push(item.key)
            });
            if(Array.isArray(defaultExpandedKeys) && defaultExpandedKeys.length>0){
                    children.map((item1)=>{
                        if(defaultExpandedKeys.includes(item1.key)){
                            if(Array.isArray(item1.props.children) && item1.props.children.length>0){
                                item1.props.children.map((item2)=>{
                                    keys.push(item2.key);
                                });
                            }
                        }
                    });
            }
            this.setState({
                keys
            });
        }
    }

    onSelect(e,item){

        document.querySelectorAll(".tree-icon-wrap").forEach(el=>{
            el.classList.remove("treeitem-selected");
        })
        e.currentTarget.classList.add("treeitem-selected");
        const {onSelect} = this.props;
        if(onSelect){
            onSelect(item);
        }
    }
    onTrigger(e,item){
        e.stopPropagation();
        item.isOpenChild = !item.isOpenChild;
        //目的就是刷新一下树
        this.setState({
            isRefresh:!this.state.isRefresh
        })
    }
    loopTree(arr){
        return  arr.map((item,index)=>{
            return <div key={index}>
                {
                    <div className="tree-title">
                        <div className="tree-icon-wrap" onClick={(e)=>this.onSelect(e,item)}>{item.title}</div>
                        {
                            item.children && item.children.length>0?
                                <div onClick={(e)=>this.onTrigger(e,item)}
                                     className="cm-ml-1">
                                    <img src={item.isOpenChild?arrowUpGray:arrowDownGray}
                                         className="tree-arrow" alt="" />
                                </div> :null
                        }
                    </div>
                }
                {
                    item.children&&item.children.length&&item.isOpenChild?
                        <div className={item.isOpenChild?"cm-display-block":"cm-display-none"}>
                            <div className="cm-ml-1" >{this.loopTree(item.children)}</div>
                        </div> :null
                }
            </div>
        })
    }
    render(){
       // console.log("111111",this.state.keys)
        const {treeData} = this.props;
        return (
           <>
               {treeData && treeData.length>0?this.loopTree(treeData):""}
           </>
        )
    }
}



export default Tree;
