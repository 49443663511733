
import React, { Component, useState } from 'react';
import { Search as SearchIcon } from '@material-ui/icons';
import { Paper, InputBase, IconButton, useMediaQuery, Button, Divider, Snackbar, AppBar, Tabs,Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CONFIG from '../../config';

function SearchBarComponent (children){
    const useStyles = makeStyles(theme => ({
        root: {
            padding: "2px 4px",
            display: 'flex',
            alignItems: 'center',
            border: "#3F51B5 1px solid"
        },
        container: {
            maxHeight: 540,
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1
        },
        IconButton: {
            padding: 10
        },
        divider: {
            height: 28, 
            margin: 4
        }
    }))
    const classes = useStyles()
    const isPc = useMediaQuery(CONFIG.minDeviceWidth)
    const [inputVal, setInputVal] = useState()
    const searchFetch = () => {
        //children.searchInput(inputVal)
        //产品、 维修、 合同查询搜索接口
    }

    const resetSearchVal = () => {
       // children.resetSearchInput()
        setInputVal("")
        document.getElementById('input').value = ''
    }
    return(
        <div style={{margin: isPc ? "30px 20px": "10px"}}>
            <Paper component="form" className={classes.root}>
                <InputBase
                id="input"
                className={classes.input}
                placeholder={"请输入产品序列号"}
                onChange={ e => setInputVal(e.target.value)}
                />
                {/* <IconButton type="submit"  className={classes.IconButton} aria-label="search" onClick={searchFetch}></IconButton> */}
                <Button className={classes.IconButton} onClick={searchFetch}><SearchIcon/></Button>
                <Divider orientation="vertical" className={classes.divider} />
                <Button variant="text" color="primary" onClick={resetSearchVal}>重置</Button>
            </Paper>
        </div>
    )
}




class VirCardHome extends Component {

    componentWillMount() {

    }

    render() {
        return (
            <div>
                <SearchBarComponent children={{}} searchInput={(e)=>{
                    console.log(e);
                }} resetSearchInput={(e)=>{
                    console.log(e);
                }}/>

                <a href="https://ming-bucket-01.oss-cn-beijing.aliyuncs.com/soft/ming_api_mock.zip">下载</a>
            </div>
        )
    }
}

export default VirCardHome;