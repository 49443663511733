import React, { useEffect, useState } from 'react';
import {
    withRouter,
} from 'react-router-dom'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    wrap: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 50,
        flexDirection: 'column',
        alignItems: 'center',
    },
    item: {
        display: 'flex',
        justifyContent: 'center',
    },
}));


const MemberReg = ({ location, history, updateSelectedSideName, updateMemberInfo }) => {
    const classes = useStyles();
    return (
        <div  style={{width: '100%'}}>
            <div className={classes.wrap}>
                <img src={"https://langjie.oss-cn-hangzhou.aliyuncs.com/space/root/project/viphelp/vip_regist_yb_voucher/guanwangzhuce.png"}/>
                <div>
                    扫码加入
                </div>
            </div>

        </div>
    );
}

MemberReg.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    updateSelectedSideName: PropTypes.func.isRequired,
}

export default withRouter(MemberReg);
