import React, { useState } from 'react';
import api from '../../api/api.js'
import apiService from '../../api/apiService';

import CONFIG from '../../config';
import {
    withRouter
} from 'react-router-dom'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FadeTransitions from '../Common/FadeTransitions'
import { Typography, CircularProgress, TableContainer, Table, TableRow, TableCell, TableBody, TableHead, IconButton, Button, GridListTile, GridListTileBar, GridList, Modal } from '@material-ui/core';
import {GetApp as GetAppIcon} from '@material-ui/icons'
import moment from 'moment';
import OpenSourceItemList from "../Common/OpenSourceItemList";
import {Toast} from "_antd-mobile@2.3.4@antd-mobile";


function RenderPanel (param) {

    const { info } = param
    let tableCellTitle = ''
    const dependencyArr = []
    const dependencyInfo = []
    const galleryInfo = []
    const galleryArr = []
    let TransRowCell = []
    const [btnInfo, setBtnInfo] = useState({
        node: <GetAppIcon/>,
        msg: '下载',
        disabled: false
    })
    if (!Object.prototype.hasOwnProperty.call(info, 'remark')) {
        info.remark = ''
    }
    TransRowCell = ['name','create_user_name','git_url','gmt_motified', "lastOpenSourceRoutineBody", 'rem']
    tableCellTitle = '附言'
    for(let key in info) {
       if (key === 'name') {
            TransRowCell[TransRowCell.indexOf(key)] = (
                <TableRow key={key}>
                    <TableCell style={{background:'#eee'}}>例程名</TableCell>
                    <TableCell>{info[key]}</TableCell>
                </TableRow>
            )
        } else if (key === 'create_user_name') {
            TransRowCell[TransRowCell.indexOf(key)] = (
                <TableRow key={key}>
                    <TableCell style={{background:'#eee'}}>上传人</TableCell>
                    <TableCell>{info[key]}</TableCell>
                </TableRow>
            )
        } else if (key === 'git_url') {
           TransRowCell[TransRowCell.indexOf(key)] = (
               <TableRow key={key}>
                   <TableCell style={{background:'#eee'}}>git地址</TableCell>
                   <TableCell><a target={"_blank"} href={info[key]}>{info[key]}</a></TableCell>
               </TableRow>
           )
       } else if (key === 'gmt_motified') {
            TransRowCell[TransRowCell.indexOf(key)] = (
                <TableRow key={key}>
                    <TableCell style={{background:'#eee'}}>更新时间</TableCell>
                    <TableCell>{moment(info[key]).format('YYYY-MM-DD')}</TableCell>
                </TableRow>
            )
        }else if (key === 'lastOpenSourceRoutineBody') {
           TransRowCell[TransRowCell.indexOf(key)] = (
               <TableRow key={key}>
                   <TableCell style={{background:'#eee'}}>最新版本</TableCell>
                   <TableCell>{
                       info[key].version? info[key].version:""
                   }</TableCell>
               </TableRow>
           )
       }
       else if (key === 'rem') {
            TransRowCell[TransRowCell.indexOf(key)] = (
                <TableRow key={key}>
                    <TableCell style={{background:'#eee'}}>{tableCellTitle}</TableCell>
                    <TableCell>{info[key]}</TableCell>
                </TableRow>
            )

        }
    }

    const downLoadPic = async (v1, v2) => {
        const param = { fileId: v1._id, picId: v2.id}
        const res = await apiService.downloadByCloudDiskId(param)
        try {
            window.open(CONFIG.url(`/open/burnDisk/download/${res.data}`))
        } catch (error) {

        }
    }

    const downLoadSource = async (e) => {

        if(e.lastOpenSourceRoutineBody.oss_file_id==null){
            Toast.info("暂无下载");
            return
        }

        setBtnInfo({
            node: <CircularProgress color="secondary" size={20} />,
            msg: '下载中...',
            disabled: true
        })
        let ossFileData=await api({
            url:"/open/ossFile/ossfileUrl/"+e.lastOpenSourceRoutineBody.oss_file_id
        });

        try {
            setBtnInfo({
                node: <GetAppIcon/>,
                msg: '下载',
                disabled: false
            })
            window.open(ossFileData.data)
        } catch (error) {

        }
    }

    const handleDownLoadDependency = async(v1, v2) => {
        const param = {fileId: v2.id, installDiskId: v1.installDiskId, type: v2.type}
        const res = await apiService.downloadDependency(param)
        try {
            window.open(CONFIG.url(`/open/burnDisk/download/${res.data}`))
        } catch (error) {

        }

    }

    return (
        <div style={{overflowY: 'auto', width: "66%", margin: "0 auto"}}>
            <TableContainer style={{width: '97%', margin: 10}}>
                <Table>
                    <TableHead><TableRow><TableCell colSpan={2}><Typography variant='body1'>详情</Typography></TableCell></TableRow></TableHead>
                    <TableBody>{TransRowCell}</TableBody>
                </Table>
            </TableContainer>
            <div style={{margin: 20, float: 'right', display: info.type === '图库' ?  'none' : 'block' }}><Button disabled={btnInfo.disabled} variant="contained" startIcon={btnInfo.node} color='primary' onClick={() => {downLoadSource(info)}}>{btnInfo.msg}</Button></div>
            <TableContainer style={{width: '97%', margin: 10}}>{dependencyInfo}</TableContainer>
            <TableContainer style={{overflow: 'hidden'}}>{galleryInfo}</TableContainer>
        </div>
    )
}

const OpenSourceRoutine = props => {

    const [info, setInfo] = useState({})
    const isPc = useMediaQuery(CONFIG.minDeviceWidth);
    const backSelectedItem = async item => {
        if (isPc) {
            const result = await apiService.fetchOpenSourceRoutineInfo({id: item.id})
            if (result.code === 200) {
                setInfo(result.data)
            }
        } else {


        }
    }


    const renderList = items => {
        const updateTime = items.updateTime === null ? '' : moment(items.updateTime).format('YYYY-MM-DD')
        return (
            <div style={{ flex: 1, padding: 4, marginLeft: 4 }}>
                <p>例程名: {items.name}</p>
                <p>更新日期: {updateTime}</p>
                <p>{items.rem}</p>
            </div>
        )
    }




    return (
        <FadeTransitions>
            <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', borderRight: '1px solid #eee' }}>
                <div style={{display: 'flex', flexDirection:'column',  width: isPc ? 400 : '100%', background: "#f5f5f5"}}>
                    <div style={{ overflow: 'auto' }}>
                        <OpenSourceItemList
                            isPc={isPc}
                            fetchList={apiService.fetchOpenSourceRoutineList}
                            renderAlbum={true}
                            renderList={renderList}
                            serviceType="CloudDisk"
                            backSelectedItem={backSelectedItem}
                        ></OpenSourceItemList>
                    </div>
                </div>
                {Object.keys(info).length !==0 ? isPc &&  <RenderPanel info={info} /> : isPc &&  <div style={{textAlign: 'center', width: '60%'}}>暂无数据</div>}
            </div>
        </FadeTransitions>
    )
}

export default withRouter(OpenSourceRoutine);