import React, { useEffect, useState} from 'react';
import { connect } from 'react-redux'
import Common from '../Common/Common'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CONFIG from '../../config';
import apiSolution from '../../api/apiSolution';
import FadeTransitions from '../Common/FadeTransitions'
import ParagraphStylesForDetail from "../Common/ParagraphStylesForDetail";
import { updateSideMenuList, updateSelectedSideMenu, updateSelectedSideName } from '../../actions'


const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({
    updateSelectedSideMenu: data => dispatch(updateSelectedSideMenu(data)),
    updateSelectedSideName: data => dispatch(updateSelectedSideName(data)),
    updateSideMenuList: data => dispatch(updateSideMenuList(data)),
})



const DocDetail = state => {
    const isPc = useMediaQuery(CONFIG.minDeviceWidth)
    const contentId = state.location.pathname.split('/')[state.location.pathname.split('/').length - 1]
    //const contentId = 222
    const [data, setData] = useState([])
    useEffect(() => {
        const fetch = async() => {
            const result = await apiSolution.fetchOpenKnowLedge(contentId)
            if(result.code === 200) setData(result.data)
        }
        fetch()
        updateSideMenu();
    },[])

    // 恢复侧边栏的显示
    const updateSideMenu = () => {
        const pagePath = state.location.pathname;
        let menuId, rootId;
        CONFIG.singlePage.forEach(items => {
            if (pagePath.indexOf(items.pathname) !== -1) {
                menuId = items.menuId;
                rootId = items.rootId
            }
        });
        const { updateSideMenuList, updateSelectedSideMenu, updateSelectedSideName } = state;
        try {
            const result = Common.getSelectMenuInfo(CONFIG.menu, menuId, rootId);
            updateSideMenuList(result.menuList);
            updateSelectedSideMenu(result.item.pathname);
            updateSelectedSideName(result.item.text);
        } catch (e) {
        }
    }



    return(
        <FadeTransitions>
            <div style={{padding: isPc ? "20px 40px" : "20px", overflow:'auto', background: '#fff'}}>
                <div>{ParagraphStylesForDetail.ContentStyles(ParagraphStylesForDetail.CommonContentRender(data))}</div>
                <div style={{height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                </div>
            </div>
        </FadeTransitions>
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DocDetail)