import React, { Component } from 'react';
import MIO from "./server.js";
import ApiGroupTreeManage from "./ApiGroupTreeManage";
import ApiDoc from "./ApiDoc.jsx";

class RootApi extends Component {
    constructor(props) {
        super(props);
        //console.log(Object.keys(MIO))
    }
    state={
        apiGroupId:null,
        apiGroupTree:[],
        apiId:992,
    }

    componentDidMount(){

    }


    render() {
        return (
            <div  className={"rootAPi"} style={{ overflow: 'auto', display: 'flex'}}>
                <div style={{ width:270, padding: '12px 16px', borderRight: '1px solid #eee' }}>
                    <ApiGroupTreeManage  parent={this}></ApiGroupTreeManage>
                </div>
                <div style={{flex:1}}>
                    <ApiDoc apiId={this.state.apiId}/>
                </div>
            </div>
        )
    }
}

export default RootApi;