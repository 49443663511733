import api from './api'

// 关于会员俱乐部
const fetchAboutMemberVipClubInfo = async params => {
    const result = await api({
        url: '/open/knowledge/197',
    });
    return result
}
//会员福利
const fetchMemberVipKnowledge= async params => {
    const result = await api({
        url: `/open/knowledge/${params.contentId}`
    })
    return result
}



// 会员活动
const MemberVipClubActivity = async params => {
    const result = await api({
        url: '/open/knowledge/memberVipClubActivity',
        queryData: {
            page: params.page ? Number(params.page) : 1,
            pageSize: params.pageSize ? Number(params.pageSize) : 30,
        },
    });
    return result;
}

const fetchRecentActivityById = async params => {
    const result = await api({
        url:  `/open/knowledge/${params.activityId}`
    })
    return result
}

// 大事记
const fetchEventRecord = async params => {
    const result = await api({
        url: '/open/knowledge/51',
    });
    return result;
}

// 联系我们
const fetchContactUs = async params => {
    const result = await api({
        url: '/open/knowledge/35',
    });
    return result;
}

//获取access_token
const fetchAccessToken = async() => {
    const result = await api({
        url: '/wx/getToken'
    })
    return result
}

export default {
    fetchAboutMemberVipClubInfo,
    fetchMemberVipKnowledge,
    MemberVipClubActivity,
    fetchRecentActivityById,
    fetchEventRecord,
    fetchContactUs,
    fetchAccessToken,
};